<template>
    <header class="blog" v-if="slides.length > 0">
        <vue-slick-carousel ref="carousel" v-bind="settings">
            <template #prevArrow>
                <div><icon icon="arrow-left" /></div>
            </template>
            <template #nextArrow>
                <div><icon icon="arrow-right" /></div>
            </template>

            <div v-for="(slide, i) in slides" :key="i">
                <b-container>
                    <b-row align-h="center" class="w-100">
                        <b-col cols="12" md="9" lg="10" xxl="12">
                            <ul class="tags" v-if="getTags(slide).length > 0">
                                <li v-for="(t,j) in getTags(slide)" :key="'t_'+j">{{ t.name }}</li>
                            </ul>
                            <h2>{{ slide.title.rendered }}</h2>
                            <b-button :href="'/blog/'+slide.slug" variant="primary">Czytaj</b-button>
                        </b-col>
                    </b-row>
                </b-container>
                <img :src="getImage(slide)" />
            </div>
        </vue-slick-carousel>
    </header>
</template>

<script>
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    // import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import Icon from '../utility/Icon';

    export default {
        name: "BlogHeader",
        components: {
            VueSlickCarousel,
            Icon,
        },
        data: function(){
            return{
                settings: {
                    arrows: true,
                    dots: true,
                },
            }
        },

        computed: {
            slides() {
                if(this.$store.state.content.posts.length === 0) return []
                return this.$store.state.content.posts.filter((p) => {
                    return this.$store.getters.setting('featured_posts').find(fp => fp.ID === p.id)
                })
            }
        },

        mounted() {
          this.$store.dispatch('getFeaturedPosts')
        },

        methods: {
            showNext(){
                this.$refs.carousel.next()
            },
            showPrev(){
                this.$refs.carousel.prev()
            },
            getTags(n) {
                return n._embedded ? n._embedded['wp:term'] ? n._embedded['wp:term'][n._embedded['wp:term'].length - 1] : [] : []
            },
            getImage(n) {
                return n['_embedded']['wp:featuredmedia']
                    ? n['_embedded']['wp:featuredmedia'][0].media_details.sizes.slide
                        ? n['_embedded']['wp:featuredmedia'][0].media_details.sizes.slide.source_url
                        : n['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full'].source_url
                    : null
            }
        },
    }
</script>

<style lang="scss">
    // slick-slide slick-active slick-current

    header.blog{
        position: relative;

        .slick{
            &-slider{
            }

            &-slide{
                position: relative;

                // Overlay
                &::after{
                    content: " ";
                    z-index: 1;
                    position: absolute;
                    left: 0; top: 0; right: 0; bottom: 0;
                    background: linear-gradient(360deg, rgba(31, 31, 31, 0.56) 0%, rgba(31, 31, 31, 0.16) 100%);
                }

                h2{ color: $white; }

                img{
                    min-height: 360px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    
                    @media all and (min-height: 667px){ min-height: 480px; }
                }

                .container{
                    display: flex;
                    position: absolute;
                    align-items: center;
                    left: 0; top: 0; right: 0; bottom: 16%;
                    z-index: 2;
                }

                 .tags{
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                    display: inline-flex;
                    flex-direction: row;
                    list-style-type: none;
                    flex-wrap: wrap;

                    li{
                        position: relative;
                        padding: 0 10px;
                        margin: 0 8px 8px 0;
                        cursor: pointer;
                        color: $primary;
                        background: $blue-100;
                        border-radius: 10px;
                        transition: 0.15s ease;
                        @extend %header-6;
                    }
                }
            }

            &-arrow{
                &.slick-prev, &.slick-next{
                    display: flex;
                    background: none;
                    border: none;
                    position: absolute;
                    bottom: 10%;
                    z-index: 2;
                    transform: translateY(-50%);
                    padding: 12px;
                    background: $white;
                    border-radius: 100%;
                    cursor: pointer;
                    &, *{ transition: 0.3s ease; }

                    svg{
                        width: 20px;
                        height: 20px;
                        path{ fill: $black; }
                    }

                    &:hover{
                        svg{ opacity: 1; }
                    }

                    @include media-breakpoint-up(lg){
                        padding: 20px;
                        top: 50%;
                        bottom: initial;

                        svg{
                            opacity: 0.4;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }

                &.slick-prev{
                    left: 16px;

                    @include media-breakpoint-up(md){ left: 24px; }
                    @include media-breakpoint-up(lg){ left: 48px; }
                }

                &.slick-next{
                    right: 16px;

                    @include media-breakpoint-up(md){ right: 24px; }
                    @include media-breakpoint-up(lg){ right: 48px; }
                }
            }

            &-dots{
                display: flex !important;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                position: absolute;
                bottom: 16%;
                left: 0;
                right: 0;
                margin: 0;
                padding: 0;
                list-style-type: none;

                li{
                    background: $gray-300;
                    border-radius: 100%;
                    width: 10px;
                    height: 10px;
                    transition: 0.3s ease;
                    cursor: pointer;

                    &:not(:last-of-type){
                        margin-right: 10px;

                        @include media-breakpoint-up(lg){ margin-right: 20px; }
                    }

                    button{ display: none; }

                    &.slick-active{
                        background: $primary;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }

        h2 + a, .tags + a{
            display: inline-block;
            margin-top: 24px;

            @include media-breakpoint-up(lg){ margin-top: 48px; }
        }
    }

    .contrast header.blog{
        .slick{
            &-slide{
                // Overlay
                &::after{
                    background: linear-gradient(360deg, rgba(31, 31, 31, 0.8) 0%, rgba(31, 31, 31, 0.56) 100%);
                }
            }
        }
    }
</style>