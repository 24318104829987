<template>
    <div class="search-bar" v-on:click="isActive = true" :class="{active: isActive}">
        <input type="text" v-bind="$attrs" @input="$emit('input', $event.target.value)">
        <icon icon="search" />
    </div>
</template>

<script>
    import Icon from './Icon';

    export default {
        name: "SearchFilter",

        components: {
            Icon,
        },

        data() {
            return {
                isActive: false
            }
        }
    }
</script>

<style lang="scss">
    .search-bar{
        position: relative;

        .icon{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            pointer-events: none;
            transition: 0.34s ease;

            svg{
                width: 20px;
                height: 20px;
                
                path{ fill: $black; }
            }
        }

        input{
            transition: 0.5s ease;
            border-radius: $border-radius;
            border: none;
            width: 100%;
            max-width: 36px;
            background: transparent;
            padding: 8px;
            cursor: pointer;

            &:focus{ outline: none; }
        }

        &:hover{
            svg path{ fill: $primary; }
        }

        &.active{
            input{
                max-width: 256px;
                background: $gray-100;
                padding-left: 48px;
            }

            input + .icon{
                left: 16px;
                transform: translate(0, -50%);
            }

            input:focus{
                box-shadow: 0 0 0 1px $primary;

                & + .icon svg path{ fill: $primary; }
            }
        }
    }
</style>

<style lang="scss">
    .search-bar{
        
    }
</style>