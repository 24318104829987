<template>
    <ul v-if="tags.length > 0" class="selected-tags">
        <li v-for="(t,i) in tags" :key="'sft_'+i" class="h6">
            <a @click="onRemove(t)">
                <span>{{ t.name }}</span>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
                    <g>
                        <defs>
                            <rect id="SVGID_1_" width="24" height="24"/>
                        </defs>
                        <clipPath id="SVGID_2_">
                            <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
                        </clipPath>
                        <g class="st0">
                            <path class="st1" d="M18.4,4.2c-0.4-0.4-1-0.4-1.4,0L12,9.2L7.1,4.2c-0.4-0.4-1-0.4-1.4,0L4.2,5.6c-0.4,0.4-0.4,1,0,1.4L9.2,12 l-4.9,4.9c-0.4,0.4-0.4,1,0,1.4l1.4,1.4c0.4,0.4,1,0.4,1.4,0l4.9-4.9l4.9,4.9c0.4,0.4,1,0.4,1.4,0l1.4-1.4c0.4-0.4,0.4-1,0-1.4 L14.8,12l4.9-4.9c0.4-0.4,0.4-1,0-1.4L18.4,4.2z"/>
                        </g>
                    </g>
                </svg>
            </a>
        </li>
    </ul>
</template>

<script>
    export default {
        name: "SelectedTagCloud",
        props: ['tags', 'onRemove']
    }
</script>

<style scoped lang="scss">
    .st0{clip-path:url(#SVGID_2_);}
    .st1{fill-rule:evenodd;clip-rule:evenodd;}

    .selected-tags{
        display: inline-flex;
        flex-direction: row;
        list-style-type: none;
        flex-wrap: wrap;
        margin: 16px 0 0;
        padding: 0;

        li{
            position: relative;
            padding: 0 5px;
            margin: 0 8px 8px 0;
            cursor: pointer;
            color: $primary;
            background: $blue-100;
            border-radius: 10px;
            transition: 0.15s ease;

            a{
                display: inline-flex;
                align-items: center;
            }

            svg{
                width: 12px;
                height: 12px;
                fill: $primary;
                transition: 0.15s ease;
                margin-left: 5px;
            }

            &:hover{
                background: $primary;
                color: $white;

                svg{ fill: $white; }
            }
        }

        @include media-breakpoint-up(lg){
            margin: 0;
        }
    }

    .contrast .selected-tags{
        li{
            background: $white;
            border: 1px solid $primary;
            
            a{ font-weight: 600; }

            &:hover{
                background: $primary;
                color: $white;

                svg{ fill: $white; }
            }
        }
    }
</style>