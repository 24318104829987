<template>
  <div>
    <blog-header/>

    <section class="decorated-top-white">
      <b-container>
        <b-row ref="filters" class="mb-5 filter-bar align-items-center align-items-lg-start" align-h="between">
          <b-col cols="auto" order="1">
            <tag-dropdown @change="addTagFilter" :tags="tags"></tag-dropdown>
          </b-col>
          <b-col order="3" order-lg="2" cols="12" lg>
            <selected-tag-cloud :tags="filters.tags" :on-remove="removeTagFilter"></selected-tag-cloud>
          </b-col>
          <b-col order="2" order-lg="3" cols="auto">
            <search-filter v-model="filters.search"></search-filter>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <entity-box-listing v-if="listingPosts.length !== 0" :items="listingPosts"></entity-box-listing>
            <div v-else class="text-center">
              <b-spinner style="width: 3rem; height: 3rem;" label="Loading Spinner"></b-spinner>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="number_of_pages > 1" class="mt-4">
          <b-col class="text-right">
            <pagination :number_of_pages="number_of_pages" :current_page="page" @change="pageChange"></pagination>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <newsletter></newsletter>
  </div>
</template>

<script>
import BlogHeader from '../components/utility/BlogHeader';
import Newsletter from "../components/blocks/Newsletter";
import Pagination from "../components/utility/Pagination";
import EntityBoxListing from "../components/utility/EntityBoxListing";
import SelectedTagCloud from "../components/utility/SelectedTagCloud";
import {tag_filter, search_filter} from '../components/mixins'
import TagDropdown from "../components/utility/TagDropdown";
import SearchFilter from '../components/utility/SearchFilter';

export default {
  name: "Blog",
  components: {TagDropdown, SelectedTagCloud, EntityBoxListing, Pagination, Newsletter, BlogHeader, SearchFilter},
  mixins: [tag_filter, search_filter],
  data() {
    return {
      page: 0,
    }
  },
  computed: {
    number_of_pages() {
      if (!this.filtersEmpty) return Math.ceil(this.posts.length / 12)
      return Math.ceil(this.$store.state.pagination.posts)
    },
    all_posts() {
      return this.$store.state.content.posts
    },
    posts() {
      return this.all_posts
          .filter(n => this.filterUsingTag(n))
          .filter(n => this.filterUsingSearch(n))
    },
    listingPosts() {
      if (this.$store.state.content.lastPostsQuery && this.filtersEmpty) return this.$store.state.content.lastPostsQuery
      return this.posts.slice(this.page * 12, this.page * 12 + 12)
    },
    filtersEmpty() {
      return this.filters.tags.length === 0 && this.filters.search === ''
    },
    search() {
      return this.filters.search
    }
  },
  methods: {
    pageChange(p) {
      this.$router.push('/blog/p/' + (p + 1))
    }
  },
  mounted() {
    if (this.$route.params.p) this.page = this.$route.params.p - 1
    else this.$store.dispatch('getBlogPage', {page: this.page + 1})
  },
  watch: {
    page(newValue) {
      this.$store.dispatch('getBlogPage', {page: (isNaN(newValue) ? 0 : newValue) + 1})
    },
    $route(to) {
      this.page = parseInt(to.params.p ?? 1) - 1
    },
    search(newValue) {
      this.$store.dispatch('searchBlog', newValue)
    }
  }
}
</script>

<style scoped lang="scss">
section {
  padding-top: 80px;
  padding-bottom: 80px;
}
</style>