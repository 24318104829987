<template>
    <b-dropdown text="Tagi" class="dropdown" variant="own">
        <b-dropdown-item @click="$emit('change', t)" v-for="(t,i) in tags" :key="'ft_'+i">{{ t.name }}</b-dropdown-item>
    </b-dropdown>
</template>

<script>
    export default {
        name: "TagDropdown",
        props: ['tags', ]
    }
</script>

<style lang="scss">
    button.dropdown-toggle{
        &, &:hover, &:active,
        &.btn-secondary:not(:disabled):not(.disabled):active,
        &.btn-secondary:not(:disabled):not(.disabled).active,
        &.show > &.btn-secondary.dropdown-toggle, &:focus{
            outline: none;
            box-shadow: none;
            background: none;
            border: none;
            color: $black;
            padding: 0;
            margin-right: 48px;
        }
    }

    .dropdown-menu{
        .dropdown-item{
            font-size: 14px;
            padding: 8px 20px;
        }
    }
</style>